export default {
    CircleReductionServiceCards: "CircleReductionServiceCards",
    CircleGrowthIconCards: "CircleGrowthIconCards",
    CircleGrowthServiceCards: "CircleGrowthServiceCards",
    DepthOfFieldCards: "DepthOfFieldCards",
    DirectionAwareCards: "DirectionAwareCards",
    FlipUpHoverCards: "FlipUpHoverCards",
    GameStyleCards: "GameStyleCards",
    GlowingCards: "GlowingCards",
    GreetingCardStyleCards: "GreetingCardStyleCards",
    HoverSwitchCards: "HoverSwitchCards",
    IconGrowthCards: "IconGrowthCards",
    ImageClipHoverCards: "ImageClipHoverCards",
    IsometricHoverCards: "IsometricHoverCards",
    ImageSlideLeftCards: "ImageSlideLeftCards",
    NeumorphismCards: "NeumorphismCards",
    SkewedBorderCards: "SkewedBorderCards",
    SwivelRotationCards: "SwivelRotationCards",
}